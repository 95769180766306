import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const primaryColor = "#333";

const Form = styled("form")({
    margin: "25px auto",
    boxShadow: "0px 3px 6px 3px rgba(153, 153, 153, 0.5)",
    webkitBoxShadow: "0px 3px 6px 3px rgba(153, 153, 153, 0.5)",
    maxWidth: 720,
    padding: 25,
    zIndex: 1,
    '@media (max-width: 768px)': {
        margin: "10px 0",
    },
})

const Container = styled("div")({
    display: "flex",
    justifyContent: "space-betweeen",
    '@media (max-width: 768px)': {
        flexDirection: "column-reverse",
    },
})

const Headline = styled("h3")({
    fontSize: 48,
    color: primaryColor,
    textTransform: "uppercase",
    fontWeight: "normal",
    margin: 0,
    flex: 1,
    '@media (max-width: 768px)': {
        fontSize: 32,
    },
})

const Image = styled("img")({
    width: 160,
    height: 30,
    '@media (max-width: 768px)': {
        marginLeft: "auto",
        height: 20,
        width: 100,
    },
})

const HeadlineHighlight = styled("span")({
    color: "#0074BB",
})

const SubHeadline = styled("h4")({
    fontSize: 14,
    color: primaryColor,
    marginTop: 0,
    '@media (max-width: 768px)': {
        fontWeight: "normal",
    },
})

const InputGroup = styled("div")({
    paddingBottom: 25,
})

const Label = styled("label")({
    color: primaryColor,
    marginBottom: 10,
    fontSize: 16,
    display: "flex",
    flexDirection: "column",
    '@media (max-width: 768px)': {
        marginBottom: 15,
        display: "flex",
        justifyContent: "space-between"
    },
})

const HelperText = styled("span")({
    marginLeft: 5,
})

const Input = styled("input")({
    border: "1px solid #E1E1E1",
    backgroundColor: "#fff",
    color: primaryColor,
    padding: 5,

})

const Select = styled("select")({
    border: "1px solid #E1E1E1",
    backgroundColor: "#fff",
    color: primaryColor,
    padding: 5,

})

const InfoGroup = styled("div")({
    backgroundColor: "#EAEAEA69",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
})

const InfoBox = styled("div")({
    flex: 1,
    padding: 15,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    '@media (max-width: 768px)': {
        display: 'flex',
        flexDirection: "column",
        flex: "none",
        justifyContent: "space-between",
        width: "100%"
    },
})

const InfoHeadline = styled("h4")({
    color: primaryColor,
    fontSize: 30,
    margin: 0,
    '@media (max-width: 768px)': {
        fontSize: 16,
        fontWeight: "bold",
    },
})

const InfoSubHeadline = styled("h5")({
    margin: 0,
    marginBottom: 5,
    color: primaryColor,
    '@media (max-width: 768px)': {
        fontSize: 14,
    },
})

const Submit = styled("button")({
    backgroundColor: "#EC5D35",
    color: "white",
    border: "none",
    borderRadius: 5,
    padding: "5px 55px",
    fontSize: 16,
    display: "block",
    margin: "0 auto",
    marginTop: 25,
})

const Disclaimer = styled("p")({
    width: "100%",
    textAlign: "center",
    paddingBottom: 15,
    margin: 0,
    color: primaryColor,
    fontSize: 12,
})


function Benefits() {
    const [salary, setSalery] = useState(15000);
    const [insuranceType, setInsuranceType] = useState(0);

    const [montlyPayment, setMontlyPayment] = useState<number>(0);

    useEffect(() => {
        if (insuranceType == 1) {
            var payout = 0;
            if (salary >= 23371) {
                var payout = Math.floor(salary * 0.9);
            } else {
                var payout = Math.floor(salary * 0.828);
            }
            if (payout >= 19351) {
                setMontlyPayment(19351);
            } else {
                setMontlyPayment(payout);
            }
        }

        if (insuranceType == 2) {
            var payout = Math.floor(salary * 0.828);

            if (payout >= 12901) {
                setMontlyPayment(12901);
            } else {
                setMontlyPayment(payout);
            }
        }

        if (insuranceType == 3) {
            setMontlyPayment(15868)
        }
        if (insuranceType == 4) {
            setMontlyPayment(13836)
        }
        if (insuranceType == 5) {
            setMontlyPayment(10579)
        }
        if (insuranceType == 6) {
            setMontlyPayment(9224)
        }
    }, [salary, insuranceType])

    function formatNumber(num) {
        if (isNaN(num) || num == null) {
            return "0";
        }
        return ("" + num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) { return $1 + "." });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Container>
                <Headline><HeadlineHighlight>Dagpenge</HeadlineHighlight>beregner</Headline>
                <Image src="/images/logo.svg" alt="logo"></Image>
            </Container>
            <SubHeadline>Udfyld detaljerne nedenfor og bliv klogere på hvad du kan forvente i dagpenge.</SubHeadline>

            <Label>Forsikringstype
                <Select value={insuranceType} onChange={e => setInsuranceType(parseInt(e.target.value))}>
                    <option value="0" disabled>Vælg forsikringstype</option>
                    <option value="1">Fuldtid</option>
                    <option value="2">Deltid</option>
                    <option value="3">Dimittend fuldtid forsørger</option>
                    <option value="4">Dimittend fuldtid ikke forsørger</option>
                    <option value="5">Dimittend deltid forsørger</option>
                    <option value="6">Dimittend deltid ikke forsørger</option>
                </Select>
            </Label>

            {insuranceType > 0 && insuranceType <= 2 ? (
                <Label>Gennemsnitlig bruttoløn pr. måned
                    <Input type="number" inputMode="numeric" value={salary} onChange={(e) => setSalery(parseInt(e.target.value))}></Input>
                </Label>
            ) : null}

            <InfoGroup>
                <InfoBox>
                    <InfoSubHeadline>Månedlig udbetaling*</InfoSubHeadline>
                    <InfoHeadline>{formatNumber(montlyPayment)} kr.</InfoHeadline>
                    <Disclaimer>* Vi gør opmærksom på at beregningen kun er vejledende. For at kunne få dagpenge skal du have været medlem af en a-kasse i et år. Husk at der gælder andre regler for elever. Se hvilke a-kasser vi anbefaler <a href="/">her</a>.</Disclaimer>
                </InfoBox>
            </InfoGroup>
        </Form>
    );
}

export default Benefits;
