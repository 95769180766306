import * as React from "react";
import * as ReactDOM from "react-dom";
import Benefits from './benefits'

const unemploymentBenefits = document.getElementById("unemploymentBenefitsModule");

if (unemploymentBenefits != null) {
    const parent = unemploymentBenefits.parentElement;
    var newElement = document.createElement("div");
    parent.insertBefore(newElement, unemploymentBenefits)

    ReactDOM.render(
        <Benefits />,
        newElement
    );
    
    parent.removeChild(unemploymentBenefits)
}

